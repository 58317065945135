<template>
  <div>
    <div class="row q-pa-xs q-pt-sm border-bottom">
      <div class="col-12">
        <slot name="header"></slot>
      </div>

      <div>
        <img
          style="height: 60px; width: 70px; object-fit: contain;"
          :src="data._embedded?.productOffer?.image || fallbackImage"
          :alt="data._embedded?.productOffer?.name"
          @error="onImageLoadFailure"
        >
      </div>

      <div v-if="!noCounter" class="col q-px-sm">
        <div v-if="!changeDetection" class="text-caption">
          <span :class="color">
            {{ $t('Total') + ': ' }}
          </span>

          <span>
            {{ data.count }}
          </span>
        </div>

        <div v-if="isExpectedItem" class="text-caption">
          <span :class="color">
            {{ $t('Expected') + ': ' }}
          </span>

          <span>
            {{ data.expected }}
          </span>
        </div>

        <div class="text-subtitle2">
          {{ name }}
        </div>

        <div v-if="changeDetection" class="row q-py-xs">
          <text-change-detection
            :key="isAcceptanceItem ? `${data.id}:${data.sku}` : data.id"
            :value="total"
            is-number
          />
        </div>
      </div>

      <div v-else class="text-subtitle2 q-px-sm">
        {{ name }}
      </div>
    </div>

    <div class="q-py-xs q-px-sm text-caption">
      <div :class="color">
        {{ $t('SKU') }}
      </div>

      <div
        class="text-bold"
        style="margin-top: -4px;"
      >
        {{ data.sku || '--' }}
      </div>

      <div v-if="data.article" :class="color">
        {{ $t('Article') }}
      </div>

      <div
        v-if="data.article"
        class="text-bold"
        style="margin-top: -4px;"
      >
        {{ data.article }}
      </div>

      <div v-if="order" :class="color">
        {{ $t('Order') }}
      </div>

      <div
        v-if="order"
        class="text-bold"
        style="margin-top: -4px;"
      >
        {{ order }}
      </div>

      <div
        v-if="data?._embedded?.warehouse"
        :class="color"
      >
          {{ $t('Warehouse') }}
      </div>

      <div
        v-if="data?._embedded?.warehouse"
        class="text-bold"
        style="margin-top: -4px;"
      >
          {{ `${data?._embedded?.warehouse?.name} (${data?._embedded?.warehouse?.id})`}}
      </div>

      <div
        v-if="data?._embedded?.shop"
        :class="color"
      >
          {{ $t('Shop') }}
      </div>

      <div
        v-if="data?._embedded?.shop"
        class="text-bold"
        style="margin-top: -4px;"
      >
          {{ `${data?._embedded?.shop?.name} (${data?._embedded?.shop?.id})`}}
      </div>

      <items-table
        v-if="details"
        :items="data.items || []"
      />
    </div>
  </div>
</template>

<script>
// Components
import ItemsTable from '../tables/ItemsTable.vue'
import TextChangeDetection from '../tools/TextChangeDetection.vue'

export default {
  name: 'ItemObject',
  components: {
    TextChangeDetection,
    ItemsTable
  },
  props: {
    noCounter: {
      type: Boolean,
      default () {
        return false
      }
    },
    isAcceptanceItem: {
      type: Boolean,
      default () {
        return false
      }
    },
    isExpectedItem: {
      type: Boolean,
      default () {
        return false
      }
    },
    isItem: {
      type: Boolean,
      default () {
        return false
      }
    },
    useItems: {
      type: Boolean,
      default () {
        return false
      }
    },
    changeDetection: {
      type: Boolean,
      defaut () {
        return false
      }
    },
    details: {
      type: Boolean,
      defaut () {
        return false
      }
    },
    data: {
      type: Object,
      required: true,
      default () {
        return null
      }
    },
    detectFix: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      fallbackImage: 'assets/img/fallback-image/package.png'
    }
  },
  computed: {
    name () {
      return this.data._embedded?.productOffer?.name || `${this.$t('SKU')}: ${this.data.sku}`
    },
    order () {
      if (!this.isItem) {
        return ''
      }

      if (this.data.order) {
        return this.data.order
      }

      if (this.data._embedded && this.data._embedded.reserve && this.data._embedded.reserve._embedded && this.data._embedded.reserve._embedded.order) {
        const parts = this.data._embedded.reserve._embedded.order._links.self.href.split('/')
        return parts[parts.length - 1]
      }

      return ''
    },
    barcode () {
      if (!this.data.barcodes) {
        return ''
      }

      if (typeof this.data.barcodes === 'string') {
        return this.data.barcodes
      }

      return this.data.barcodes[this.data.barcodes.length - 1]
    },
    color () {
      return this.$q.dark.isActive
        ? 'text-white'
        : 'text-grey-9'
    },
    total () {
      if (this.isAcceptanceItem) {
        return this.data.quantity || 0
      }

      if (this.isItem) {
        return 1
      }

      if (!this.useItems) {
        return this.data.count || 0
      }

      if (!this.data.items) {
        return 1
      }

      return this.data.items.reduce((acc, item) => {
        return acc + item.count
      }, 0)
    }
  },
  beforeMount () {
    if (this.detectFix) {
      // eslint-disable-next-line vue/no-mutating-props
      this.data.count -= 1
    }
  },
  mounted () {
    if (this.detectFix) {
      this.$nextTick(() => {
      // eslint-disable-next-line vue/no-mutating-props
        this.data.count += 1
      })
    }
  },
  methods: {
    onImageLoadFailure (e) {
      e.target.src = this.fallbackImage
    }
  }
}
</script>
