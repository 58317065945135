<template>
  <div>
    <q-card
      v-for="item in items"
      :key="item.id"
      class="q-mb-sm"
      style="box-shadow: 0 0 6px 1px rgb(0 0 0 / 20%);"
    >
      <div class="row items-center border-bottom q-px-sm q-py-xs">
        <q-badge
          :color="stateColors[item.state]"
          class="q-py-xs"
        >
          <span>
            {{ $t(stateNames[item.state] || item.state) + ': ' }}
          </span>

          <span class="text-bold q-ml-sm">
            {{ item.places.reduce((acc, place) => place.item.count + acc, 0) || 0 }}
          </span>
        </q-badge>
      </div>

      <div v-if="item.places.length > 0 && item.state !== 'expected' && item.state !== 'new'">
        <div
          v-for="place in item.places"
          :key="place.id"
          class="row items-center justify-between q-px-sm q-py-xs border-bottom"
        >
          <div class="col-12 row">
            <div class="col row items-center">
              <div v-if="place.id" class="full-width">
                <div class="text-caption">
                  {{ `${place.type} (S/P/${place.id}*)` }}
                </div>

                <div class="row text-center text-subtitle1 no-wrap" style="margin-top: -4px;">
                  <template
                    v-for="(item, i) in getPath(place)"
                    :key="`${item}: ${i}`"
                  >
                    <div
                      class="col q-px-xs"
                      style="white-space: pre-wrap !important;"
                    >
                      <strong>{{ item || 0 }}</strong>
                    </div>

                    <div
                      v-if="i + 1 < getPath(place).length"
                      class="col"
                    >
                      -
                    </div>
                  </template>
                </div>
              </div>
            </div>

            <div>
              <div class="text-caption">
                {{ $t('Quantity') }}
              </div>

              <div class="text-bold text-subtitle1" style="margin-top: -4px;">
                {{ place.item.count || 0 }}
              </div>
            </div>
          </div>

          <div
            v-if="appOptions.debug"
            class="col-6"
            style="margin-top: -4px;"
          >
            <div class="text-caption">
              {{ $t('ID') }}
            </div>

            <div class="text-bold text-subtitle2" style="margin-top: -4px;">
              {{ place.id }}
            </div>
          </div>

          <div
            v-if="place.item.order"
            class="col-6"
            style="margin-top: -4px;"
          >
            <div class="text-caption">
              {{ $t('Order') }}
            </div>

            <div class="text-bold text-subtitle2" style="margin-top: -4px;">
              {{ place.item.order }}
            </div>
          </div>
        </div>
      </div>
    </q-card>
  </div>
</template>

<script>
export default {
  name: 'ProductStatesTable',
  props: {
    items: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      appOptions: this.$appOptions,
      stateColors: {
        new: 'grey-5',
        blocked: 'danger',
        booked: 'danger',
        deleted: 'danger',
        shipped: 'green',
        normal: 'success',
        expected: 'green',
        missing: 'grey'
      },
      stateNames: {
        normal: 'On hand',
        blocked: 'Blocked',
        booked: 'Booked',
        new: 'Received'
      },
      sub: null
    }
  },
  mounted () {
    this.sub = this.$eventBus.subscribe('rules', ({ appOptions }) => {
      this.appOptions = appOptions
    })
  },
  unmounted () {
    this.sub.unsubscribe()
  },
  methods: {
    getPath (place) {
      if (!place.path) {
        if (place.type === 'room') {
          return [place.placeAlias || '']
        }

        return [place.room, place.row, place.rack, place.shelf].map(x => x || 0)
      }

      return [...place.path, (place.placeAlias || '')]
    }
  }
}
</script>
