<template>
  <div class="row q-pa-xs q-pt-sm border-bottom">
    <div class="col-12">
        <slot name="header"></slot>
    </div>
    <div>
        <img
            class="m-auto"
            style="height: 70px; width: 70px; object-fit: contain;"
            :src="item._embedded?.productOffer?.image? item._embedded.productOffer.image : fallbackImage"
            @error="onImageLoadFailure"
        >
    </div>

    <div  class="q-py-xs q-px-sm text-caption">
        <div :class="color">
            {{ $t('Name') }}
        </div>

        <div
                class="text-bold"
                style="margin-top: -4px; min-width: 15%"
        >
            {{item._embedded?.productOffer?.name}}
        </div>

        <div :class="color">
            {{ $t('ID') }}
        </div>

        <div
                class="text-bold"
                style="margin-top: -4px"
        >
            <strong>{{item.id}}</strong>
        </div>
    </div>

  </div>

  <div class="q-py-xs q-px-sm text-caption">
      <div :class="color">
          {{ $t('State') }}
      </div>

      <div
          class="text-bold"
          style="margin-top: -4px; max-width: 15%"
      >
          <super-dropdown :model="schema(item)" />
      </div>

      <div :class="color">
          {{ $t('Type') }}
      </div>

      <div
          v-if="item.type"
          class="text-bold"
          style="margin-top: -4px;"
      >
          {{item.type}}
      </div>

      <div :class="color">
          {{ $t('Warehouse') }}
      </div>

      <div
              v-if="item && item._embedded && item._embedded.warehouse"
              class="text-bold"
              style="margin-top: -4px;"
      >
          {{item._embedded.warehouse.name}}
      </div>

      <div :class="color">
          {{ $t('Reserve') }}
      </div>

      <div
          class="text-bold"
          style="margin-top: -4px;"
      >
          <div v-if="item && item._embedded && item._embedded.reserve">
              {{item._embedded.reserve.id}}
          </div>
          <div v-else>
              {{'--'}}
          </div>

      </div>

      <div :class="color">
          {{ $t('SKU') }}
      </div>

      <div
          class="text-bold"
          style="margin-top: -4px;"
      >
          {{ item.sku || '--' }}
      </div>

      <div :class="color">
          {{ $t('Path') }}
      </div>

      <div
          class="text-bold"
          style="margin-top: -4px;"
      >
          <div class="row text-center text-caption no-wrap q-px-sm items-center">
              <template
                      v-for="(item, i) in path"
                      :key="`${item}: ${i}`"
              >
                  <div
                          class="q-px-xs"
                  >
                      <strong>{{ item || 0 }}</strong>
                  </div>

                  <div
                          v-if="i + 1 < path.length"
                          class="q-px-sm"
                  >
                      -
                  </div>
              </template>
          </div>
      </div>
  </div>
</template>

<script>

import SuperDropdown from "@/apps/app/components/super-dropdown/SuperDropdown.vue";

export default {
  name: 'PlaceObject',
    components: {SuperDropdown},
  emits: ['click'],
  props: {
    bg: {
      type: String,
      default () {
        return 'bg-white'
      }
    },
    item: {
      type: Object,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      appOptions: this.$appOptions,
      path: [],
      sub: null,
      fallbackImage: 'assets/img/fallback-image/package.png',
      itemStates: [
        {
          buttons: [
            { id: 'normal', title: 'Normal', transitions: [], color: 'success' },
            { id: 'delivered', title: 'Delivered', transitions: [], color: 'positive' },
            { id: 'blocked', title: 'Blocked', transitions: [], color: 'danger' },
            { id: 'booked', title: 'Booked', transitions: [], color: 'danger' },
            { id: 'deleted', title: 'Deleted', transitions: [], color: 'danger' },
            { id: 'new', title: 'New', transitions: [], color: 'grey-3'},
            { id: 'returned', title: 'Returned', transitions: [], color: 'warning'},
            { id: 'shipped' , title: 'Shipped', transitions: [], color: 'green text-white'},
            { id: 'expected', title: 'Expected' , transitions: [], color: 'green text-white'},
            { id: 'defected', title: 'Defected', transitions: [], color : 'dark text-white'}
          ]
        }
      ],
    }
  },
  watch: {
    item (value) {
      if(value && value._embedded && value._embedded.place) {
        this.loadPath(value._embedded.place)
      }
    }
  },
  mounted () {
    if(this.item && this.item._embedded && this.item._embedded.place) {
      this.loadPath(this.item._embedded.place)
    }

    this.sub = this.$eventBus.subscribe('rules', ({ appOptions }) => {
      this.appOptions = appOptions
    })
  },
  unmounted () {
    this.sub.unsubscribe()
  },
  methods: {
    schema (row) {
        const value = this.itemStates[0].buttons.find(({ id }) => id === row.state) || { id: 'normal', title: 'Active' }
        const option = {
            id: 'statuses',
            type: 'super-dropdown',
            variant: 'link',
            color: value.color || 'default',
            disabledLabels: true,
            editableFields: false,
            onClick: (state) => {

            },
            value,
            options: this.itemStates
        }

        return option
    },
    loadPath (place) {
      if (!place.path) {
        place.path = [place.type].map(x => x || 0)
      }

      this.path = [...place.path, (place.placeAlias || '')]
    },
    handleClick () {
      this.$emit('click', this.rawData)
    },
    onImageLoadFailure (e) {
      e.target.src = this.fallbackImage
    },
    color () {
      return this.$q.dark.isActive
        ? 'text-white'
        : 'text-grey-9'
    },
  }
}
</script>
