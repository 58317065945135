<template>
  <div class="terminal-height relative">
    <portal to="header">
      <terminal-header
          :title="$t('Info')"
          @barcode="handleBarcode"
      />
    </portal>

    <portal to="settings">
      <tiles :schema="tilesSchema"/>
    </portal>

    <jumbotron
        v-if="(!place && items.length <= 0 ) && (!acceptance && storageItems.length <= 0)"
    />

    <div
        v-if="place"
        class="q-pa-xs"
    >
      <place-object
          :data="place"
          :type="place.type"
      />
    </div>

    <div v-if="dynamicPlaces.length > 0">
      <div class="row justify-center q-mt-sm">
        <strong class="text-center">{{ $t('Dynamic places') }}</strong>
      </div>

      <div
          v-for="dynamicPlace in dynamicPlaces"
          :key="dynamicPlace.id"
      >
        <place-object
            :data="dynamicPlace"
            :type="dynamicPlace.type"
        />
      </div>
    </div>

    <div>
      <div class="row justify-center">
        <div
            v-for="item in items"
            :key="item.id"
            class="col-12 q-mb-sm"
            :class="itemClass"
        >
          <item-object
              :data="item"
              :change-detection="false"
              :details="!!place"
              :use-items="!place"
          />
        </div>
      </div>

      <div
          v-if="places.length > 0"
          class="q-px-xs"
      >
        <product-states-table :items="places"/>
      </div>

      <div
          v-if="items.length === 0 && isLoadedItems"
          class="terminal-footer terminal-footer--middle"
      >
        <p>{{ $t('No Items Found!') }}</p>
      </div>
    </div>

    <div
        v-if="acceptance"
        class="q-pa-xs"
    >
      <acceptance-object
          :data="acceptance"
      />
    </div>

    <div
        v-if="acceptance"
        class="q-pa-xs"
        ref="scrollTargetRef"
        style="max-height: calc(100vh - 100px); overflow: auto"
    >
      <q-infinite-scroll
          @load="loadStorageItems"
          :disable="page >= totalPages || isLoading"
          :offset="250"
          :scroll-target="$refs.scrollTargetRef"
      >
        <q-card
            v-for="item in storageItems"
            :key="item.id"
            class="q-mb-sm"
        >
          <storage-item :item="item"/>
        </q-card>
      </q-infinite-scroll>


      <div
          v-if="storageItems.length === 0 && isLoadedStorageItems"
          class="terminal-footer terminal-footer--middle"
      >
        <p>{{ $t('No Items Found!') }}</p>
      </div>
    </div>

    <q-dialog v-model="isOpenImage">
      <q-card>
        <q-card-section class="row items-center q-pb-none">
          <q-space/>

          <q-btn
              v-close-popup
              icon="close"
              flat
              round
              dense
          />
        </q-card-section>

        <q-card-section>
          <img
              :src="image"
              style="width: 100%; height: auto; object-fit: cover;"
              alt="item image"
              @error="onImageLoadFailure"
          >
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Components
import ProductStatesTable from '../components/tables/ProductStatesTable.vue'
import { buildQuery } from '@/apps/app/utils/query-utils'
import StorageItem from '@/apps/terminal/components/objects/storage-item.vue'
import ItemObject from '@/apps/terminal/components/objects/item-object.vue'

export default {
  name: 'ItemsInfo',
  components: {
    ItemObject,
    StorageItem,
    ProductStatesTable
  },
  data () {
    return {
      columns: [
        {
          label: this.$t('Place'),
          name: 'place',
          align: 'center'
        },
        {
          label: this.$t('Quantity'),
          name: 'quantity',
          align: 'center'
        },
        {
          label: this.$t('Order'),
          name: 'order',
          align: 'center'
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'center'
        }
      ],
      stateColors: {
        new: 'grey-5',
        blocked: 'danger',
        booked: 'danger',
        deleted: 'danger',
        shipped: 'green',
        normal: 'success',
        expected: 'green',
        missing: 'grey'
      },
      stateNames: {
        normal: 'On hand',
        blocked: 'Blocked',
        booked: 'Booked',
        new: 'Received'
      },
      fallbackImage: 'assets/img/fallback-image/package.png',
      isOpenImage: false,
      image: null,
      place: null,
      items: [],
      places: [],
      dynamicPlaces: [],
      acceptance: null,
      storageItems: [],
      isLoadedItems: false,
      isLoadedStorageItems: false,
      page: 0,
      totalPages: 1,
      isLoading: false,
      debug: false,
      helper: null,
      priority: {
        normal: 10,
        new: 9,
        expected: 8
      },
      timeout: null
    }
  },
  computed: {
    ...mapGetters([
      'helpers',
      'terminalContainerHeight'
    ]),
    tilesSchema () {
      return []
    },
    itemClass () {
      return this.items.length > 1
          ? 'border-bottom'
          : ''
    },
    textPosition () {
      if (!this.item && !this.place && this.places.length <= 0 && this.items.length <= 0) {
        return 'center'
      }

      return 'bottom'
    }
  },
  mounted () {
    const data = {
      title: this.$t('Please scan product or location.'),
      description: 'In that page you can scan product or location and we will give you information about what quantity you have.',
      cover: 'assets/terminal/dashboard-medium.png',
      disabledNotifications: true
    }

    this.addInstructionHelper(data)
        .then(helper => {
          this.helper = helper
        })
  },
  unmounted () {
    this.deleteAllHelpers()

    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  },
  methods: {
    ...mapMutations([
      'addErrorNotification',
      'removeHelper',
      'updateHelper',
      'deleteAllHelpers'
    ]),
    ...mapActions([
      'addHelper',
      'addInstructionHelper',
      'reactivateHelper'
    ]),
    handleDebug () {
      this.debug = !this.debug
    },
    handleOpenCamera () {
      this.$refs.barcodeField.openCamera()
    },
    onImageLoadFailure (e) {
      e.target.src = this.fallbackImage
    },
    handleImageClick (item) {
      this.image = item.image || this.fallbackImage
      this.isOpenImage = true
    },
    scanItem (barcode) {
      const query = {
        action: 'get-info',
        criteria: {
          sku: barcode.raw
        }
      }

      return this.$service.storageItem.getAll(query)
          .then(data => {
            this.isLoadedItems = true
            this.place = null
            this.items = [
              {
                ...data.result.productOffer,
                count: this.places.reduce((acc, place) => {
                  return acc + Number((place.item || {}).count || 0)
                }, 0)
              }
            ]

            this.places = this.groupPlacesByItemState(data.result.places || [], this.priority)
          })
    },
    groupPlacesByItemState (places, priority = {}) {
      return Object.values(
          places.reduce((acc, place) => {
            if (!acc[place.item.state]) {
              acc[place.item.state] = {
                id: Math.floor(Math.random() * (9999999999) + 1),
                state: place.item.state,
                places: []
              }
            }

            acc[place.item.state].places.push(place)

            return acc
          }, {})
      ).sort((a, b) => (priority[b.state] || 0) - (priority[a.state] || 0))
    },
    scanAcceptance (barcode) {
      return this.$service.acceptance.get(barcode.value)
          .then(item => {
            this.acceptance = item
            this.loadStorageItems(item)
          })
    },
    loadStorageItems (item) {
      if (this.page >= this.totalPages) {
        return
      }

      this.page += 1
      this.isLoading = true

      const query = {
        per_page: 25,
        page: this.page,
      }

      query.filter = [{ type: 'eq', field: 'createdByDocument', value: this.acceptance.id }]
      if (this.acceptance && this.acceptance._embedded && this.acceptance._embedded.warehouse) {
        query.filter.push({ type: 'eq', field: 'warehouse', value: this.acceptance._embedded.warehouse.id })
      }

      return this.$service.storageItemEntity.getAll(query)
          .then(data => {
            if (this.page <= 1) {
              this.storageItems = data.items
            } else {
              this.storageItems = [...this.storageItems, ...data.items]
            }

            this.totalPages = data.totalPages
            this.isLoadedStorageItems = true
            return data
          })
          .catch(() => {
            this.page -= 1
          })
          .finally(() => {
            this.isLoading = false
          })
    },
    scanPlace (barcode) {
      return this.$service.storagePlace.get(barcode.value)
          .then(place => {
            const query = {
              per_page: 250,
              page: 1,
              filter: [
                { type: 'eq', field: 'place', value: barcode.value }
              ],
              group: [
                { field: 'place', alias: 'i' },
                { field: 'productOffer', alias: 'i' },
                { field: 'sku', alias: 'i' }
              ]
            }

            // this.$utils.formatGroupedItemsByOffer(data.items)
            return this.$service.storageItemEntity.getAll(query)
                .then(({ items }) => {
                  if (items.length <= 0) {
                    if (this.timeout) {
                      clearTimeout(this.timeout)
                    }

                    if (this.helper) {
                      this.removeHelper(this.helper.id)
                    }

                    this.addHelper({ message: 'No products on that location', type: 'message' })
                        .then(h => {
                          this.helper = h
                        })

                    this.timeout = setTimeout(() => {
                      if (this.helper) {
                        this.removeHelper(this.helper.id)
                      }

                      this.addHelper({ message: 'Please scan product or location', type: 'message' })
                          .then(h => {
                            this.helper = h
                          })

                      this.timeout = null
                    }, 6000)
                  }

                  this.items = items
                  this.place = place
                  this.places = []
                  this.dynamicPlaces = []

                  this.$service.storagePlace.getAll({
                    per_page: 250,
                    page: 1,
                    filter: [
                      { type: 'eq', field: 'parent', value: place.id }
                    ],
                  }).then(({ items }) => {
                    this.dynamicPlaces = [...this.dynamicPlaces, ...items]
                  })
                })
          })
    },
    handleBarcode (barcode) {
      if (barcode.type === 'S/P') {
        return this.scanPlace(barcode)
      }

      if (barcode.type === 'S/D') {
        return this.scanAcceptance(barcode)
      }

      return this.scanItem(barcode)
    }
  }
}
</script>
